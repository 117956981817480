<template>
  <div class="columns is-multiline is-vcentered">
    <div class="column is-12">
      <h3 class="subtitle has-text-weight-bold">
        Información General
      </h3>
    </div>
    <template v-if="loading">
      <div class="column is-10">
        <loading />
      </div>
    </template>
    <template v-else>
      <div class="column is-12">
        <div class="columns is-multiline">
          <div class="column is-4">
            <label class="label">Nombre</label>
            <template>
              <p>
                {{ name }}
              </p>
            </template>
          </div>
          <div class="column is-4">
            <label class="label">Contacto</label>
            <template>
              <p>
                {{ contact }}
              </p>
            </template>
          </div>
          <div class="column is-4">
            <label class="label">Teléfono</label>
            <template>
              <p>
                {{ phone }}
              </p>
            </template>
          </div>
          <div class="column is-4">
            <label class="label">Socio</label>
            <template>
              <p>
                {{ partner }}
              </p>
            </template>
          </div>
          <div class="column is-4">
            <label class="label">País</label>
            <template>
              <p>
                {{ country }}
              </p>
            </template>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'GeneralInformation',
  components: {
    Loading: () => import('@/components/ui/Loading.vue')
  },
  data () {
    return {
      loading: false,
      customerId: this.$route.params.customerId,
      name: null,
      contact: null,
      phone: null,
      partner: null,
      country: null
    }
  },
  methods: {
    ...mapActions(['getCustomerById']),
    async getCustomerDetail () {
      const { success, detail } = await this.getCustomerById(this.customerId)
      if (success) {
        this.dataBackup = detail
        this.setLocalValues(detail)
      }
      this.loading = false
    },
    setLocalValues (data) {
      this.name = data.name
      this.contact = data.contact
      this.phone = data.phone
      this.partner = data.partner_name
      this.country = data.country_name
    }
  },
  beforeMount () {
    this.loading = true
    this.getCustomerDetail()
  }
}
</script>

<style lang="scss" scoped>
  .input {
    font-size: 14px;
  }
  .field.is-grouped {
    display: flex;
    justify-content: flex-end;
  }
  .content_text_switch {
    min-width: 70px;
    display: inline-block;
  }
  .text_active {
    min-width: 60px;
    display: inline-block;
  }
  .user_active_content {
    display: flex;
    align-items: center;
  }
</style>
